import { h } from 'preact';
import SvgIcon from '@mui/material/SvgIcon';

export const RandomIcon = props => (
  <SvgIcon {...props} width="1200pt" height="1200pt" viewBox="0 0 1200 1200" >
    <g>
      <path d="m97.199 433.2c96 0 189.6 39.602 255.6 109.2l9.6016 9.6016 93.602-97.199-4.8008-4.8008c-91.199-96-220.8-151.2-352.8-151.2-37.199 0-67.199 30-67.199 67.199-1.2031 37.199 28.797 67.199 65.996 67.199z"/>
      <path d="m1154.4 804-200.4-145.2c-25.199-18-60 0-60 31.199v76.801c-92.398-2.3984-180-42-243.6-108l-9.5977-10.801-93.602 97.199 4.8008 4.8008c88.801 93.602 212.4 147.6 342 150v78c0 31.199 34.801 49.199 60 31.199l200.4-145.2c20.402-14.398 20.402-45.602 0-60z"/>
      <path d="m894 433.2v76.801c0 31.199 34.801 49.199 60 31.199l200.4-145.2c20.398-15.602 20.398-45.602 0-61.199l-200.4-145.2c-25.199-18-60 0-60 31.199v78c-128.4 2.3984-253.2 57.598-342 150l-199.2 208.8c-66 69.602-159.6 109.2-255.6 109.2-37.199 0-67.199 30-67.199 67.199s30 67.199 67.199 67.199c132 0 261.6-55.199 352.8-151.2l199.2-208.8c64.801-66 152.4-104.4 244.8-108z"/>
    </g>
  </SvgIcon>
);

